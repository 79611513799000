import { ref, watch } from '@vue/composition-api'
// import store from '@/store'

export default function useCustomerHandler(props, emit, signAgain) {
  // ------------------------------------------------
  // customerLocal
  // ------------------------------------------------
  const customerLocal = ref(JSON.parse(JSON.stringify(props.customer.value)))
  const resetCustomerLocal = () => {
    customerLocal.value = JSON.parse(JSON.stringify(props.customer.value))
  }
  watch(props.customer, () => {
    resetCustomerLocal()
  })

  // ------------------------------------------------
  // isEventHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ! We can hide it using @hidden event
  // ------------------------------------------------
  // watch(props.isEventHandlerSidebarActive, val => {
  //   // ? Don't reset event till transition is finished
  //   if (!val) {
  //     setTimeout(() => {
  //       clearForm.value()
  //     }, 350)
  //   }
  // })

  const onSubmit = () => {
    const customerData = JSON.parse(JSON.stringify(customerLocal))

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.isSignAgain) emit('sign-again', customerData.value)

    if (props.customer.value.id) emit('update-customer', customerData.value)
    else emit('add-customer', customerData.value)

    // Close sidebar
    emit('update:is-customer-handler-sidebar-active', false)
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // ------------------------------------------------
  // guestOptions
  // ------------------------------------------------


  return {
    customerLocal,
    resetCustomerLocal,

    // UI
    onSubmit,
  }
}