<template>
  <component :is="customer === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="customer === undefined">
      <h4 class="alert-heading">Error fetching customer data</h4>
      <div class="alert-body">
        No user found with this customer id. Check
        <b-link class="alert-link" :to="{ name: 'apps-customers' }">
          User List
        </b-link>
        for other customers.
      </div>
    </b-alert>

    <customer-edit-tab-account
      :customer="customer"
      :role-options="roleOptions"
      @update-customer="updateCustomer"
      @remove-customer="removeCustomer"
      class="mt-2 pt-75"
    />
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BToast,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import customerStoreModule from "../customerStoreModule";
import CustomerEditTabAccount from "./CustomerEditTabAccount.vue";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BToast,
    BFormInvalidFeedback,
    CustomerEditTabAccount,
    ToastificationContent,
  },
  setup(props, context) {
    const CUSTOMER_STORE_MODULE_NAME = 'app-customer'

    // Register module
    if (!store.hasModule(CUSTOMER_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_STORE_MODULE_NAME, customerStoreModule)

    // Unregister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_STORE_MODULE_NAME)
    })

    const roleOptions = [
      { label: "admin", value: "admin" },
      { label: "customer", value: "customer" },
    ];

    const blankCustomer = {
      id: null,
      "adresse": {
        "kanton": "",
        "ort": "",
        "postleitzahl": "",
        "strassename": "",
        "strassenummer": ""
      },
      "anamnese": {
        "answers": [
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true
        ],
        "unterschrift": ""
      },
      "emailadresse": "",
      "geburtsdatum": "",
      "gender": "Mann",
      "kundenvereinbarung": {
        "vereinbarung_1": "vereinbarung1",
        "vereinbarung_2": "vereinbarung2",
        "vereinbarung_3": "vereinbarung3",
        "visum_1": "",
        "visum_2": "",
        "visum_3": ""
      },
      "marketingvereinbarung": true,
      "mitarbeiter_id": 1,
      "nachname": "",
      "telefonnummer": "",
      "vorname": ""
    };
    const customer = ref(JSON.parse(JSON.stringify(blankCustomer)));

    store
      .dispatch("app-customer/fetchCustomer", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        customer.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          customer.value = undefined;
        }
      });

    const removeCustomer = () => {
      store
        .dispatch("app-customer/removeCustomer", { id: customer.value.id })
        .then(() => {
          router.push({ name: "customers-list" });
        })
    };
    const updateCustomer = (employeeData) => {
      store.dispatch("app-customer/updateCustomer", employeeData).then(() => {
        // eslint-disable-next-line no-use-before-define
        router.push({ name: "customers-view", params: { id: employeeData.id } });
      });
    };

    return {
      customer,
      roleOptions,
      removeCustomer,
      updateCustomer,
    };
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.business-card .business-items .business-item {
  padding: 0.6rem 1rem 0rem 1rem !important;
}
hr.dotted {
  margin-top: 30px;
  border-top: 3px dotted #bbb;
}
</style>
