<template>
  <div>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <!-- User Info: Input Fields -->
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <validation-observer ref="personalInfoRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">{{$t('Personal Information')}}</h5>
            </b-col>
            <b-col md="6">
              <!-- Vorname -->
              <validation-provider
                #default="validationContext"
                name="Vorname"
                rules="required"
              >
                <b-form-group :label="$t('First Name')" label-for="vorname">
                  <b-form-input
                    id="vorname"
                    v-model="customerLocal.vorname"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            
            <b-col md="6">
              <!-- Nachname -->
              <validation-provider
                #default="validationContext"
                name="Nachname"
                rules="required"
              >
                <b-form-group :label="$t('Last Name')" label-for="nachname">
                  <b-form-input
                    id="nachname"
                    v-model="customerLocal.nachname"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <!-- Nachname -->
              <!-- Geburtsdatum -->
              <validation-provider
                #default="validationContext"
                name="Geburtsdatum"
                rules="required"
              >
                <b-form-group :label="$t('Date of Birth')" label-for="geburtsdatum">
                  <flat-pickr
                    v-model="customerLocal.geburtsdatum"
                    :class="{'form-control flatpickr-input': true, 'is-invalid': validationContext.errors.length > 0, 'is-valid': getValidationState(validationContext) == true }"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Gender -->
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Gender"
                rules="required"
              >
                <b-form-group :label="$t('Gender')" label-for="Gender">
                  <b-form-radio-group
                    id="gender"
                    v-model="customerLocal.gender"
                    name="gender"
                    :options="genders"
                    class="inline-spacing"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <!-- Email -->
              <validation-provider
                #default="validationContext"
                name="Emailadresse"
                rules="required|email"
              >
                <b-form-group :label="$t('Email Address')" label-for="emailadresse">
                  <b-form-input
                    id="emailadresse"
                    v-model="customerLocal.emailadresse"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <!-- Telefonnummer -->
              <validation-provider
                #default="validationContext"
                name="Telefonnummer"
                rules="required"
              >
                <b-form-group :label="$t('Phone Number')" label-for="telefonnummer">
                  <b-form-input
                    id="telefonnummer"
                    v-model="customerLocal.telefonnummer"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <br />
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">{{ $t('Address') }}</h5>
            </b-col>
            <b-col md="6">
              <!-- Strassename -->
              <validation-provider
                #default="validationContext"
                name="Strassename"
                rules="required"
              >
                <b-form-group :label="$t('Street Name')" label-for="strassename">
                  <b-form-input
                    id="strassename"
                    v-model="customerLocal.adresse.strassename"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <!-- Strassenummer -->
              <validation-provider
                #default="validationContext"
                name="Strassenummer"
                rules="required"
              >
                <b-form-group :label="$t('Street Number')" label-for="strassenummer">
                  <b-form-input
                    id="strassenummer"
                    v-model="customerLocal.adresse.strassenummer"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <!-- Postleitzahl -->
              <validation-provider
                #default="validationContext"
                name="Postleitzahl"
                rules="required"
              >
                <b-form-group :label="$t('Postal Code')" label-for="postleitzahl">
                  <b-form-input
                    id="postleitzahl"
                    v-model="customerLocal.adresse.postleitzahl"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <!-- Ort -->
              <validation-provider
                #default="validationContext"
                name="Ort"
                rules="required"
              >
                <b-form-group :label="$t('Location')" label-for="ort">
                  <b-form-input
                    id="ort"
                    v-model="customerLocal.adresse.ort"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <!-- Kanton -->
              <validation-provider
                #default="validationContext"
                name="Kanton"
                rules="required"
              >
                <b-form-group :label="$t('Canton')" label-for="kanton">
                  <b-form-input
                    id="kanton"
                    v-model="customerLocal.adresse.kanton"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

         
        </validation-observer>
        <!-- Action Buttons -->
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        type="submit"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        {{ $t("Save") }}
      </b-button>

      <b-button @click="showMsgDeleteConfirm" variant="outline-secondary" class="ml-1">
        <span class="d-none d-sm-inline">Remove</span>
        <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
      </b-button>
      </b-form>

      
    </validation-observer>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vueEsign from "vue-esign";
import Ripple from "vue-ripple-directive";
import i18n from "@/libs/i18n";
import { toRefs, watch, onMounted } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import useCustomerHandler from "../useCustomerHandler";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
  BButton,
  BCard,
  BCardText,
  BCardTitle,
  BFormRadioGroup,
  BFormInvalidFeedback,
} from "bootstrap-vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    vueEsign,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BButton,
    BCard,
    BCardText,
    BCardTitle,
    BFormRadioGroup,
    flatPickr,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    customer: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      locale: 'de',
      errorMsg: null,
      required,
      alphaNum,
      email,
      lineWidth: 3,
      lineColor: "#000000",
      bgColor: "",
      resultImg: "",
      isCrop: false,
      selectedContry: "select_value",
      selectedLanguage: "nothing_selected",
      gdpr: false,
      marketingvereinbarung: false,
      confirmAnswer: false,
      genders: [
        {
          text: this.$i18n.t('Mister'),
          value: "Herr"
        },
        {
          text: this.$i18n.t('Woman'),
          value: "Frau"
        }],
    };
  },
  setup(props, { emit }) {
    const { customerLocal, resetCustomerLocal, onSubmit } = useCustomerHandler(
      toRefs(props),
      emit
    );

    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation(resetCustomerLocal, props.clearCustomerData);

    return {
      customerLocal,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    };
  },
  methods: {
    showMsgDeleteConfirm() {
      this.$bvModal
        .msgBoxConfirm(i18n.t('Are you sure you want to delete this customer?'), {
          title: i18n.t('Confirm'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: i18n.t('Yes'),
          cancelTitle: i18n.t('No'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$emit('remove-customer');
          }
        })
    },
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.inline-spacing {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  & > * {
    margin-right: 1.5rem;
    margin-top: 0.5rem;
  }
}
</style>

<style scoped>
.esign {
  max-width: 1000px;
  margin: 10px 0px;
}
.esigh-btns button {
  color: #fff;
  height: 40px;
  width: 100px;
  font-size: 16px;
  margin-right: 10px;
  outline: none;
  border-radius: 4px;
  background: #f60;
  border: 1px solid transparent;
}
.esigh-btns button:active {
  color: #fff;
  box-shadow: 0px 0px 50px orangered inset;
}
.esigh-result {
  margin-top: 10px 0px;
}
.esigh-result img {
  display: block;
  max-width: 100%;
  height: auto;
  border: 1px solid #ececee;
}
.esignature {
  border: 1px solid #ebe9f1;
  border-radius: 0.357rem;
}
span.error {
  color: #e74c3c;
}
</style>